
























































import { PropType } from 'vue';
import * as vr from '@/helpers/validation';
import { SuperAdminGameProvider } from '@/api/schema';

export default {
  name: 'SuperAdminProvidersCreateProviderAccountDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    gameProviders: {
      type: Array as PropType<SuperAdminGameProvider[]>
    }
  },
  data(): any {
    return {
      providerName: '',
      email: '',
      formRules: {
        providerName: [vr.required],
        email: [vr.required, vr.isEmail]
      },
      isFormValid: true
    };
  },
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    async handlerOnSubmit(): Promise<void> {
      const form = this.$refs.form;
      const isFormValid = await form.validate();
      if (isFormValid) {
        this.$emit('submit', this.providerName, this.email);
      }
    },
    handlerOnClose(): void {
      this.$refs.form.reset();
      this.show = false;
    },
    getGameProviderName({ name }: SuperAdminGameProvider): string {
      return name;
    }
  }
};
