





























import SuperAdminProvidersCreateProviderAccountDialog from '@/views/SuperAdminViews/super-admin-providers/SuperAdminProvidersCreateProviderAccountDialog.vue';
import {
  SuperAdminGameProvider,
  SuperAdminGameProviderAccount
} from '@/api/schema';
import {
  getSuperAdminGameProviders,
  getSuperAdminGameProvidersAccounts,
  createSuperAdminGameProvidersAccounts
} from '@/api/SuperAdmin';
import { errorToastMessage } from '@/helpers/errorToastMessage';

export default {
  name: 'SuperAdminProvidersAccounts',
  components: {
    SuperAdminProvidersCreateProviderAccountDialog
  },
  data(): any {
    return {
      isCreateProviderAccountDialogVisible: false,
      providersAccountsTableHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Brand Name', value: 'gameProviderName' },
        { text: 'Email', value: 'email' },
        { text: 'Created At', value: 'createdAt' }
      ],
      providersAccountsTableData: [] as SuperAdminGameProviderAccount[],
      providersAccountsTableOptions: {
        page: 1,
        itemsPerPage: 10
      },
      providersAccountsTotalElements: -1,
      isProvidersAccountsLoading: true,
      gameProviders: [] as SuperAdminGameProvider[],
      isGameProvidersLoading: false,
      isSubmit: false
    };
  },
  computed: {
    providersAccountsTableDataRequestParams(): {
      page: number;
      size: number;
    } {
      const { page, itemsPerPage } = this.providersAccountsTableOptions;
      return {
        page: page - 1,
        size: itemsPerPage
      };
    }
  },
  methods: {
    handlerOnProvidersTableRowClick({
      id
    }: SuperAdminGameProviderAccount): void {
      this.$router.push({
        name: 'game-provider',
        params: { id: String(id) }
      });
    },
    async handlerCreateNewGameProviderAccount(
      providerName: string,
      email: string
    ): Promise<void> {
      try {
        this.isSubmit = true;
        const { gameProviderName } =
          await createSuperAdminGameProvidersAccounts({
            providerName,
            email
          });

        this.$toast.success(
          `Account for game provider ${gameProviderName} was created`
        );
        this.setCreateProviderAccountDialogVisible(false);

        void this.fetchProvidersTableData();
      } catch (e) {
        console.error('handlerCreateNewGameProviderAccount: ', e);
        errorToastMessage(e);
      } finally {
        this.isSubmit = false;
      }
    },
    async fetchProvidersTableData(): Promise<void> {
      try {
        this.isProvidersAccountsLoading = true;
        const { content, totalElements } =
          await getSuperAdminGameProvidersAccounts(
            this.providersAccountsTableDataRequestParams
          );
        this.providersAccountsTotalElements = totalElements;
        this.providersAccountsTableData = content;
      } finally {
        this.isProvidersAccountsLoading = false;
      }
    },
    async fetchGameProvidersData(): Promise<void> {
      try {
        this.isGameProvidersLoading = true;
        this.gameProviders = await getSuperAdminGameProviders();
      } finally {
        this.isGameProvidersLoading = false;
      }
    },
    setCreateProviderAccountDialogVisible(payload: boolean): void {
      if (payload && this.gameProviders.length === 0) {
        this.fetchGameProvidersData();
      }
      this.isCreateProviderAccountDialogVisible = payload;
    }
  }
};
